import * as singleSpa from 'single-spa';
import { LifeCycles } from 'single-spa';
import { MfeApplicationEnum } from '@landr/core.models';
import { Log } from '@landr/root-log';
import { logFactory, LogFactoryOptions } from './log';
import { Config, loadConfig } from './config';
import { AbstractSegmentServiceV2, AnalyticsApplication, Environment, getNetworkLink } from '@landr/core';
import { isProfileMfeLocation } from './activityFunctions';
import { bootstrap, getOfflineUrl, hideLoader } from '@landr/root-app/init-helpers';
import { auth } from '@landr/root-auth';
import { FeatureFlags, featureFlagService } from '@landr/root-feature-flags';

declare global {
    interface Window {
        prerendercloudReady?: boolean;
        LANDR_NETWORK_ROOT_ANGULAR_CONFIG: Config;
        LANDR_NETWORK_LOG_FACTORY: (options: LogFactoryOptions) => Log;
    }
}

export function importViteModule(packageName: string) {
    const url = System.resolve(packageName);

    return import(/* webpackIgnore: true */ url);
}

const registerApplications = (coreMfeLoadPromise: Promise<LifeCycles>): void => {
    singleSpa.registerApplication({
        name: MfeApplicationEnum.CoreMfe,
        app: () => coreMfeLoadPromise,
        activeWhen: () => true,
        customProps: {
            getPageNotFoundActivity: () => false,
        },
    });

    singleSpa.registerApplication({
        name: MfeApplicationEnum.ProfileMfe,
        app: async () => {
            const module = await importViteModule('@landr/profile-mfe');
            await coreMfeLoadPromise;
            return module.load();
        },
        activeWhen: (location) => isProfileMfeLocation(location),
    });

    singleSpa.registerApplication({
        name: '@landr/network-angular-mfe',
        app: () =>
            System.import('@landr/network-angular-mfe').then((module) => {
                return coreMfeLoadPromise.then(() => coreMfeLoadPromise).then(() => module.load());
            }),
        activeWhen: (location) => !isProfileMfeLocation(location),
        customProps: {
            rootAppOrigin: 'legacy',
        },
    });
};

function redirectToOfflinePage(offlineUrl: string) {
    setTimeout(() => {
        window.location.assign(getOfflineUrl(offlineUrl));
    }, 2000);
}

function handleMainAppRedirection(isAuthenticated: boolean, env: Environment) {
    const flags = featureFlagService.get();
    const hasNetworkAngularMfeInsideMainApp = flags[FeatureFlags.NetworkAngularMFE];
    const isRedirectedToMainApp = isAuthenticated && hasNetworkAngularMfeInsideMainApp;

    if (isRedirectedToMainApp) {
        const location = window.location;
        const mainAppLink = getNetworkLink(env, `${location.pathname}${location.search}`, {
            // return uri prefixed with /network
            isNetworkAngularMfeInWebApp: true,
        });
        window.location.replace(mainAppLink);
    }
}

async function run() {
    // Bootstrap config
    const config = await loadConfig();
    window.LANDR_NETWORK_ROOT_ANGULAR_CONFIG = Object.freeze(config);

    // Based on config, bootstrap root app logs
    const logRootAppConfig = {
        serviceKey: 'NetworkRootApp',
        tags: 'Web,MFE,NetworkRootApp',
    };
    const log = logFactory(config, null)(logRootAppConfig);
    // Create analytics that will NOT be init in some cases (prerender, loggin on behalf)
    const analyticsV2 = new AbstractSegmentServiceV2(AnalyticsApplication.Network, window);

    // Callback from Stripe is using 'code' and 'state' as URL params, which
    // will make the Auth authentication failed and Stripe will failed also.
    // We need to change location at bootstrap
    const urlParams = new URLSearchParams(window.location.search);
    const isCallbackFromStripeConnect = window.location.pathname === '/users/me/connect';
    const stripeCode = urlParams.get('code');
    const stripeState = urlParams.get('state');
    if (isCallbackFromStripeConnect && stripeCode && stripeState) {
        const params = new URLSearchParams();
        params.set('stripeCode', stripeCode);
        params.set('stripeState', stripeState);
        window.history.replaceState({}, '', `${window.location.pathname}?${params}`);
    }

    await bootstrap({
        onError: (error) => {
            if (error && (error as any).appOrParcelName && (error as any).appOrParcelName !== 'core-mfe') {
                // Single spa error
                return importViteModule('@landr/core-mfe').then((module) => {
                    module.showGlobalError();
                    hideLoader();
                });
            } else {
                redirectToOfflinePage(config.offlineUrl);
            }
        },
        devTools: true,
        offlineConversions: true,
        registerApplications,
        devToolsConfig: Object.fromEntries(
            Object.entries(config)
                // We can only show a flat tree in dev tools.
                .map(([key, value]) => [key, JSON.stringify(value) ?? '⚠️ Unserializable object.']),
        ),
        offlineUrl: config.offlineUrl,
        environment: config.landrEnv?.toLowerCase() as Environment,
        onBeforeRegisterApplications: async () => {
            const user = await auth.getUser();
            window.LANDR_NETWORK_LOG_FACTORY = logFactory(config, user);
            handleMainAppRedirection(!!user, config.landrEnv?.toLowerCase() as Environment);
        },
        analytics: analyticsV2,
        segmentWriteKey: config.networkSegmentWriteKey,
        applicationName: config.appName,
        applicationVersion: config.appVersion,
        featureFlagApi: config.featureFlagApiBaseUrl,
        authConfig: {
            domain: config.fusionAuthDomain,
            clientId: config.fusionAuthClientId,
            homepageForAnalyticsFallback: 'Network',
        },
        log,
        singleSpaOptions: {
            urlRerouteOnly: true,
        },
    });
}

run();
