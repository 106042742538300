import { LogLevel } from '@landr/core';
import { ApplicationEnum } from '@landr/core.models';

export type ConfigLogOptions = {
    enabled: boolean;
    separator: string;
    console: {
        enabled: boolean;
        minLevel: LogLevel;
    };
    loggly: {
        enabled: boolean;
        apiKey: string;
        minLevel: LogLevel;
        tags: string;
        maxBundleSize: number;
        consecutiveOccurrences: number;
    };
};

export type Config = {
    appName: ApplicationEnum;
    appVersion: string;
    landrEnv: string;

    offlineUrl: string;
    mainAppUrl: string;

    networkSegmentWriteKey: string;

    fusionAuthDomain: string;
    fusionAuthClientId: string;

    featureFlagApiBaseUrl: string;

    logOptions: ConfigLogOptions;
};

const FusionAuthClientIdPerTargetEnv = {
    dev: '356d0197-b80e-4ba5-aa2d-90a6d1eefd78',
    demo: 'eb4e2915-ed2a-46cc-b539-41f66d7109ff',
    partner: '767b1c9b-8912-44c8-a446-ec36e48331f6',
};

const LandrEnvPerTargetEnv = {
    dev: 'Development',
    demo: 'Demo',
    partner: 'Partner',
};

export const loadConfig = async (): Promise<Config> => {
    const importConfig = (await import(
        /* webpackChunkName: "config" */
        /* webpackMode: "lazy-once" */
        /* webpackPrefetch: true */
        /* webpackPreload: true */
        './config.json'
    )) as any;
    const config = importConfig.default as Config;

    // Override with local settings
    if (process.env.NODE_ENV === 'development') {
        const targetEnv = process.env.TARGET_ENV;
        config.landrEnv = LandrEnvPerTargetEnv[targetEnv as keyof typeof LandrEnvPerTargetEnv] || 'Development';
        config.appVersion = 'NOT a deployable build!';

        // Network app
        config.networkSegmentWriteKey = 'd9x3CqTBnxZxDCpk5HiyVlyoSaUzvFD6';

        // FusionAuth
        config.fusionAuthDomain = `https://accounts-${targetEnv}.landr.com`;
        config.fusionAuthClientId =
            FusionAuthClientIdPerTargetEnv[targetEnv as keyof typeof FusionAuthClientIdPerTargetEnv] || '';

        // Base URLs
        config.featureFlagApiBaseUrl = `https://api3-${targetEnv}.landr.com/featureflag`;

        // Logs
        config.logOptions.console.minLevel = LogLevel.DEBUG;
        config.logOptions.loggly.enabled = false;
    }

    return config;
};
